<template>
  <Page title="Portfolios" color="success">
    <template slot="extension">
      <v-tabs background-color="success darken-1" hide-slider>
        <v-toolbar color="transparent" flat dense>
          <v-spacer />
          <v-text-field
            v-model="search"
            single-line
            dense
            hide-details
            label="Name"
            append-icon="mdi-magnify"
            clearable
          />
        </v-toolbar>
      </v-tabs>
    </template>
    <v-alert
      v-if="items.length > 0"
      type="info"
      text
      border="left"
      :prominent="!$vuetify.breakpoint.xs"
    >
      <v-row align="center">
        <v-col class="grow">
          Es befinden sich {{ items.length }} Portfolio{{
            items.length != 1 ? "s" : ""
          }}
          in deiner Sammlung.
        </v-col>
        <v-col class="shrink">
          <v-btn
            color="info"
            icon
            @click="overview = true"
            v-if="$vuetify.breakpoint.mobile"
            ><v-icon>mdi-download</v-icon></v-btn
          >
          <v-btn v-else color="info" outlined text @click="overview = true"
            >Herunterladen</v-btn
          >
        </v-col>
      </v-row>
    </v-alert>
    <v-alert v-else type="error" text border="left">
      Du hast leider noch kein Portfolio
    </v-alert>
    <v-card :loading="loading" flat>
      <v-row>
        <v-col
          v-for="item in itemsFiltered"
          :key="item.id"
          xl="2"
          lg="3"
          md="4"
          sm="6"
          cols="12"
          align-stretch
        >
          <v-card
            class="d-flex flex-column fill-height"
            :to="{ name: 'PortfolioDetail', params: { id: item.id } }"
          >
            <v-system-bar>{{ item.title }}</v-system-bar>
            <v-list>
              <PersonItemBasic :value="item.student" />
              <v-divider />
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-calendar</v-icon>
                </v-list-item-icon>
                <v-list-item-content
                  ><v-list-item-title>
                    {{ formatDate(item.creationDate) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <PersonName :value="item.creator" ext />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-bottom-sheet v-model="overview" inset max-width="500">
      <v-card tile>
        <v-card-text class="title pt-8 text-center">
          Portfolio{{ items.length != 1 ? "s" : "" }}<br />
        </v-card-text>
        <v-card-text class="display text-center">
          <v-avatar> <PortraitImage :value="$_profilePerson" /> </v-avatar
          ><br />
          <PersonName :value="$_profilePerson" />
        </v-card-text>
        <v-divider />
        <v-list>
          <v-list-item
            @click="
              apiReport({
                resource: 'report/portfoliooverview',
                data: {
                  type: 'student',
                  id: $_profilePerson.id,
                },
                download: true,
              })
            "
          >
            <v-list-item-icon
              ><v-icon>mdi-file-document</v-icon></v-list-item-icon
            >
            <v-list-item-content
              >Portfolioübersicht herunterladen (pdf)
            </v-list-item-content></v-list-item
          >
          <v-list-item
            @click="
              apiReport({
                resource: 'report/portfolio',
                data: {
                  type: 'student',
                  id: $_profilePerson.id,
                },
                download: true,
              })
            "
          >
            <v-list-item-icon
              ><v-icon>mdi-file-document-multiple</v-icon></v-list-item-icon
            >
            <v-list-item-content
              >{{ items.length }} Portfolios herunterladen (pdf)
            </v-list-item-content></v-list-item
          >
        </v-list>

        <v-divider />
        <v-card-actions
          ><v-btn text block @click="overview = false">schliessen</v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
    <router-view />
  </Page>
</template>

<script>
import PersonItemBasic from "common/components/PersonItemBasic.vue";
import PersonName from "common/components/PersonName.vue";
import PortraitImage from "common/components/PortraitImage.vue";
import { formatDate } from "common/utils/date.js";
import { searchPerson } from "common/utils/people.js";
export default {
  components: { PersonItemBasic, PersonName, PortraitImage },
  data() {
    return {
      items: [],
      loading: false,
      overview: false,
      search: "",
    };
  },
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (item) =>
          !this.search ||
          item.title.toLowerCase().includes(this.search.toLowerCase()) ||
          searchPerson(item.student, this.search.toLowerCase())
      );
    },
  },
  methods: {
    formatDate,
    async fetchData() {
      this.loading = true;
      this.items = await this.apiList({ resource: "portfolio/portfolio" });
      this.loading = false;
    },
  },
  async created() {
    this.fetchData();
  },
};
</script>
